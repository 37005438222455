import { Component, OnInit, Input, TemplateRef } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {NgbOffcanvas} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})

/***
 * Header Component
 */
export class HeaderComponent implements OnInit {

  @Input() sliderTopbar: boolean;
  @Input() Settingicon: boolean;
  @Input() Menuoption: string;
  @Input() linkRedirecionamento: string;

  public href: string = "";


  constructor(private router: Router, private modalService: NgbModal, private offcanvasService: NgbOffcanvas) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
      }
    });
  }

  isCondensed = false;

  ngAfterViewInit() {
  }

  ngOnInit(): void {
    this.href = this.router.url;
    if (this.router.url == '/index-classic-saas') {
      var light_btn = document.querySelectorAll(".login-btn-primary")
      light_btn.forEach(element => {
        (element as HTMLElement).style.display = "none";
      });

      var dark_btn = document.querySelectorAll(".login-btn-light")
      dark_btn.forEach(element => {
        (element as HTMLElement).style.display = "block";
      });
    }
    else if (this.router.url == '/index-apps') {
      document.querySelector('.app-header').classList.remove('d-none');
    }
    else {
      var light_btn = document.querySelectorAll(".login-btn-primary")
      light_btn.forEach(element => {
        (element as HTMLElement).style.display = "block";
      });

      var dark_btn = document.querySelectorAll(".login-btn-light")
      dark_btn.forEach(element => {
        (element as HTMLElement).style.display = "none";
      });
      
    }
  }

  /**
   * Window scroll method
   */
  // tslint:disable-next-line: typedef
  windowScroll() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      document.getElementById("topnav").classList.add("nav-sticky");

    } else {
      document.getElementById("topnav").classList.remove("nav-sticky");
      }
    if (document.getElementById("back-to-top")) {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        document.getElementById("back-to-top").style.display = "inline";
      } else {
        document.getElementById("back-to-top").style.display = "none";
      }
    }
  }
  /**
   * Toggle menu
   */
  toggleMenu() {
    this.isCondensed = !this.isCondensed;
    if (this.isCondensed) {
      document.getElementById("navigation").style.display = "block";
    } else {
      document.getElementById("navigation").style.display = "none";
    }
  }

  /**
   * Menu clicked show the submenu
   */
  onMenuClick(event) {
    event.preventDefault();
    const nextEl = event.target.nextSibling.nextSibling;
    if (nextEl && !nextEl.classList.contains("open")) {
      const parentEl = event.target.parentNode;
      if (parentEl) {
        parentEl.classList.remove("open");
      }
      nextEl.classList.add("open");
    } else if (nextEl) {
      nextEl.classList.remove("open");
    }
    return false;
  }

  // Demo Offcanvas
  openright(content: TemplateRef<any>) {  
    this.offcanvasService.open(
      content, 
      { position: 'end' }
    );
  }

}
