import { Component } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.css'
})
export class PageNotFoundComponent {
  Menuoption: string = '404';
  Settingicon: boolean = false;
  linkRedirecionamento: string = '/home';

}
