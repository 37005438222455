import { CommonModule } from '@angular/common';
import { Component, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { LoaderService } from 'src/app/_core/services/loader.service';
import { EnderecoV1Model } from 'src/app/auth/models/account-v1.model';
import { FormReativoService } from 'src/app/shared/services/form-reativo.service';
import { MasksUtil } from 'src/app/shared/services/masks.util';
import { ViaCepService } from 'src/app/shared/services/via-cep.service';

@Component({
  selector: 'app-form-endereco-faturamento',
  templateUrl: './form-endereco-faturamento.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  styles: ``
})
export class FormEnderecoFaturamentoComponent {
  formEnderecoFaturamento: FormGroup;
  isCepValido: boolean = false;

  static dadosEnderecoFaturamento = new Subject<EnderecoV1Model>();
  constructor(
    private fb: FormBuilder,
    public formReativo: FormReativoService,
    public masksUtils: MasksUtil,
    private viaCepService: ViaCepService,
    private loaderService: LoaderService,
    private renderer: Renderer2
  ) {
    this.formEnderecoFaturamento = this.fb.group(this.montarFormEnderecoFaturamento());
  }

  montarFormEnderecoFaturamento() {
    return {
      cepTitular: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(/\d+/)
        ])
      ],
      logradouro: '',
      numeroEndereco: [
        '',
        Validators.required,
      ],
      bairro: '',
      cidade: '',
      estado: '',
    }
  }

  get controls() {
    return this.formEnderecoFaturamento.controls;
  }

  /**
  * Função acionada quando o input do cep for alterado
  */
  async aoInformarOCep() {
    /**
     * Se o control cep estiver válido (com o min e o max length atingido),
     * o if é atendido e acessado
     */
    if (this.controls['cepTitular'].valid!) {
      const cep = this.controls['cepTitular'].value;
      this.loaderService.startLoader();
      await this.viaCepService.buscarEnderecoPorCep(cep)
        .subscribe({
          next: data => {
            if (!data.erro) {
              this.formEnderecoFaturamento.patchValue({
                logradouro: data.logradouro,
                bairro: data.bairro,
                cidade: data.localidade,
                estado: data.uf
              });

              this.controls['bairro'].disable();
              this.controls['cidade'].disable();
              this.controls['estado'].disable();

              // endereco = `${data.logradouro} - ${data.bairro} - ${data.localidade}/${data.uf} - ${data.cep}`;
              this.isCepValido = true;
              this.loaderService.stopLoader();
            } else {
              this.isCepValido = false;
              this.loaderService.stopLoader();
            }
          },
          error: () => {
            this.loaderService.stopLoader();
            this.controls['cepTitular'].setErrors([{pattern: true}]);
            // expect(this.controls['cepTitular'].valid).toEqual(false);
          }
        });
    }
  }

  emitirEnderecoFaturamento() {
    if (this.formEnderecoFaturamento.valid) {
      const dadosAEmitir = {
        cep: this.controls['cepTitular'].value,
        logradouro: this.controls['logradouro'].value,
        numero: this.controls['numeroEndereco'].value,
        bairro: this.controls['bairro'].value,
        cidade: this.controls['cidade'].value,
        uf: this.controls['estado'].value,
      }
      FormEnderecoFaturamentoComponent.dadosEnderecoFaturamento.next(dadosAEmitir);
    }
  }

  proximoInput(formControl: string, maxLength: number, proxInput: string) {
    if (this.controls[formControl]?.value.length == maxLength) {
      this.renderer.selectRootElement(`#${proxInput}`)?.focus();
    }
  };
}
