// Components do Sistema
import { Injectable } from "@angular/core";
import { CanActivate, Router } from '@angular/router';
// Utils
import { SecurityUtil } from '../utils/security.util';
// Services
import { UtilsService } from "../utils/utils.service";
import Swal from "sweetalert2";

@Injectable()
export class AuthGuard implements CanActivate {

	constructor(
		private route: Router,
		private utilsService: UtilsService,
	) { }

	canActivate() {
		if (!SecurityUtil.getToken()) {
			Swal.fire(
        'Acesso não autorizado!',
        'Ops, você não tem autorização para concluir esta solicitação. Necessário fazer login com uma conta válida.',
        'error'
      ).then(() => {
				this.route.navigate(['/home']);
			});
			return false;
		} else {
			this.utilsService.refreshtoken();
			return true;
		}
	}
}
