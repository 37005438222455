import { environment } from "src/environments/environment.prod";
export const SAAS = "extratoofx";

/**URL para acesso à API Asaas*/
export const API_ASAAS = environment.apiAsaas;

/**Account Token para permissão de acesso à API Asaas */
export const accessTokenAsaas = environment.accessTokenAsaas;

/**URL para acesso à API Conversor de Dados */
export const API_CONVERSOR = environment.apiConversor;

/**Account Token para permissão de acesso à API Conversor de Dados */
export const accessTokenConversor = environment.accessTokenConversor
