<form [formGroup]="formEmissaoNF">
  <div class="row g-3">
    <div class="col-12 col-md-6">
      <label class="form-label">CNPJ: <b class="text-danger">*</b></label>
      <input type="text" class="form-control form-control-sm" formControlName="cnpjEmpresaNF"
        (input)="masksUtils.maskCnpj($event)" (keyup)="emitirDadosEmissaoNF()"
        [ngClass]="formReativo.definindoClasseValidator(formEmissaoNF, 'cnpjEmpresaNF')"
        (keyup)="proximoInput('cnpjEmpresaNF', 14, 'razaoSocial')">
      @if(controls['cnpjEmpresaNF'].dirty){
      @if(controls['cnpjEmpresaNF'].errors?.['required']){
      <small class="text-danger">*Campo obrigatório.</small>
      }@else if(controls['cnpjEmpresaNF'].errors?.['minlength'] ||
      controls['cnpjEmpresaNF'].errors?.['maxlength']){
      <small class="text-danger">*Informe um CNPJ válido.</small>
      }
      }
    </div>
    <div class="col-12 col-md-6">
      <label class="form-label">Razão Social: <b class="text-danger">*</b></label>
      <input type="text" class="form-control form-control-sm" formControlName="razaoSocialNF" id="razaoSocial"
        [ngClass]="formReativo.definindoClasseValidator(formEmissaoNF, 'razaoSocialNF')" (input)="toUpperCase($event)"
        (keyup)="emitirDadosEmissaoNF()">
      @if(controls['razaoSocialNF'].dirty && controls['razaoSocialNF'].invalid){
      <small class="text-danger">*Campo obrigatório.</small>
      }
    </div>
    <div class="col-12 col-md-6">
      <label class="form-label">E-mail: <b class="text-danger">*</b></label>
      <input type="email" class="form-control form-control-sm" formControlName="emailEmpresaNF"
        [ngClass]="formReativo.definindoClasseValidator(formEmissaoNF, 'emailEmpresaNF')"
        (keyup)="emitirDadosEmissaoNF()" id="emailEmpresa">
      @if(controls['emailEmpresaNF'].errors && controls['emailEmpresaNF'].dirty){
      <small class="text-danger">*Campo obrigatório.</small>
      }
      @if(emailUsuario!){
      <div class="form-check mt-1 mb-0 form-check-primary">
        <input type="checkbox" name="utilizar-email" id="utilizar-email" [(ngModel)]="isUtilizarMesmoEmail"
          (ngModelChange)="utilizarMesmoEmail()" [ngModelOptions]="{standalone: true}"
          class="form-check-input cursor-pointer">
        <label for="utilizar-email" class="form-check-label cursor-pointer">
          Mesmo email de acesso?
        </label>
      </div>
      }
    </div>
  </div>
</form>