import { Component } from '@angular/core';

@Component({
  selector: 'app-logo-bancos',
  templateUrl: './logo-bancos.component.html',
  styleUrls: ['./logo-bancos.component.css']
})
export class LogoBancosComponent {
  /**
     * Imagens do Carrosel
     */
  carroselImagens: {
    urlImagem: string,
    nome: string,
    altura: number,
  }[] = [
    {
      urlImagem: "/assets/imgs/bancos/banco-original-sem-bg.png",
      nome: 'Banco Original',
      altura: 50,
    },
    {
      urlImagem: "/assets/imgs/bancos/bb.png",
      nome: 'Banco do Brasil',
      altura: 50,
    },
    {
      urlImagem: "/assets/imgs/bancos/logo-mercado-pago-1024.png",
      nome: 'Mercado Pago',
      altura: 50,
    },
    {
      urlImagem: "/assets/imgs/bancos/nu.png",
      nome: 'Nubank',
      altura: 50,
    },
    {
      urlImagem: "/assets/imgs/bancos/pagbank-logo-1-1.png",
      nome: 'PagBank',
      altura: 50,
    },
    {
      urlImagem: "/assets/imgs/bancos/bradesco.png",
      nome: 'Bradesco',
      altura: 50,
    },
    {
      urlImagem: "/assets/imgs/bancos/QI-Tech-Logo-360x119.png",
      nome: 'Qi Sociedade de Crédito',
      altura: 50,
    },
    {
      urlImagem: "/assets/imgs/bancos/banco-safra.png",
      nome: 'Banco Safra',
      altura: 50,
    },
    {
      urlImagem: "/assets/imgs/bancos/santander-sem-bg.png",
      nome: 'Banco Santander',
      altura: 50,
    },
    {
      urlImagem: "/assets/imgs/bancos/itau.png",
      nome: 'Banco Itaú',
      altura: 50,
    },
    {
      urlImagem: "/assets/imgs/bancos/sicoob-logo-1-1.png",
      nome: 'Banco Sicoob',
      altura: 50,
    },
    {
      urlImagem: "/assets/imgs/bancos/caixa.png",
      nome: 'Caixa (CEF)',
      altura: 50,
    },
    {
      urlImagem: "/assets/imgs/bancos/sicredi-sem-bg.png",
      nome: 'Banco Sicredi',
      altura: 50,
    },
  ];
}
