@if(planosCard!){
<div class="container-fluid container-md my-5">
  <div class="row justify-content-center">
    <div class="col-12 text-center">
      <div class="section-title">
        <h4 class="title">
          Escolha seu plano 
          <a [href]="helpLink" target="_blank" ngbTooltip="Clique para obter ajuda" placement="right"
            style="color: #007DB9;">
            <i-feather name="help-circle" class="fea icon-sm icons cursor-pointer"></i-feather>
          </a>
        </h4>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    @for(data of planosCard; track $index){
    @if(!isDescontoAplicado){
    <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
      <div class="card pricing pricing-primary business-rate shadow border-0 rounded cursor-pointer"
        [ngClass]="{'bg-home-blue': data.melhorOpcao}">
        <div class="ribbon ribbon-right ribbon-warning overflow-hidden"><span
            class="text-center d-block shadow small h6">{{data.melhorOpcao}}</span></div>
        <div class="card-body">
          <h6 class="title fw-bold text-uppercase text-primary mb-0">{{data.titulo}}</h6>
          <div class="d-flex mb-0">
            <span class="h4 mb-0 mt-2">R$</span>
            <span class="price h1 mb-0">{{data.valor.split(',')[0]}}</span>
            <span class="h4 align-self-end mb-2">,{{data.valor.split(',')[1]}}</span>
          </div>
          <ul class="list-unstyled mb-0 ps-0">
            @if(data.isDesc){
            <li class="h6 text-danger mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-times-circle align-middle text-danger"></i></span>{{data.desconto}}</li>
            }@else{
            <li class="h6 mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>{{data.desconto}}</li>
            }
          </ul>
          @for(item of data.list;track $index){
          <ul class="list-unstyled mb-0 ps-0">
            <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>{{item}}</li>
          </ul>
          }
          <button type="button" class="btn btn-primary mt-4" (click)="assinar(data)">
            {{data.btn}}
          </button>
        </div>
      </div>
    </div>
    }@else {
    <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
      <div class="card pricing pricing-primary business-rate shadow border-0 rounded cursor-pointer"
        [ngClass]="{'bg-home-blue': data.melhorOpcao}">
        <div class="ribbon ribbon-right ribbon-warning overflow-hidden">
          <span class="text-center d-block shadow small h6">
            {{data.melhorOpcao}}
          </span>
        </div>
        <div class="card-body">
          <h6 class="title fw-bold text-uppercase text-primary mb-0">
            {{data.titulo}}
          </h6>
          <div class="text-end">
            <span class="h6 mb-0 mt-2 text-danger">R$ {{data.valor}}</span>
            <div class="d-xxl-flex justify-content-end" style="margin-top: -0.8rem;">
              <span class="h4 mb-0 mt-2" [style]="!isDesktop ? 'font-size: 1.6rem' : ''">R$</span>
              <span class="price h1 mb-0"
                [style]="!isDesktop ? 'font-size: 2rem' : ''">{{data.valorTotalComCupom.split(',')[0]}}</span>
              <span class="h4 align-self-end mb-1"
                [style]="!isDesktop ? 'font-size: 1.6rem;' : ''">,{{data.valorTotalComCupom.split(',')[1]}}</span>
            </div>
          </div>
          <div class="text-end mb-2" style="margin-top: -0.8rem;">
            <small class="text-success">Cupom aplicado: R$ {{data.descontoCupom}}</small>
          </div>
          <ul class="list-unstyled mb-0 ps-0">
            @if(data.isDesc){
            <li class="h6 text-danger mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-times-circle align-middle text-danger"></i></span>{{data.desconto}}</li>
            }@else{
            <li class="h6 mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>{{data.desconto}}</li>
            }
          </ul>
          @for(item of data.list;track $index){
          <ul class="list-unstyled mb-0 ps-0">
            <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>{{item}}</li>
          </ul>
          }
          <button type="button" class="btn btn-primary mt-4" (click)="assinar(data)">
            {{data.btn}}
          </button>
        </div>
      </div>
    </div>
    }
    }
    <div class="text-center" [ngClass]="isDesktop ? 'mt-2' : 'mt-3'">
      <small class="text-muted">*O valor de desconto aplicado refere-se ao cálculo do plano mensal no
        período de 12 meses.</small>
    </div>
    <div class="row justify-content-center">
      <div class="col text-center">
        <button type="button" aria-hidden="false" class="btn mt-3 btn-sm"
          [ngClass]="isDescontoAplicado ? 'btn-danger' : 'btn-warning'"
          (click)="isDescontoAplicado ? removerCupom() : abrirModalInserirCupom()">
          {{isDescontoAplicado ? 'Remover Cupom' : 'Possui cupom de desconto?'}}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="container text-center my-3">
  <div class="row g-3 justify-content-md-center">
    <div class="col-12 col-md-4 col-lg-2">
      <div class="picsum-img-wrapper text-center filter-img">
        <div class="d-none d-md-block">&nbsp;</div>
        <a target="_blank" href="https://www.ssllabs.com/ssltest/analyze.html?d=extratoofx.com&latest">
          <img src="assets/imgs/certificado.png" [height]="50" 
            alt="um cadeado em verde, fechado, que indica que o site possui o certificado ssl, o tornando um site seguro"/>
        </a>
      </div>
    </div>
    <div class="col-12 col-md-4 col-lg-2">
      <div class="picsum-img-wrapper text-center filter-img">
        <div id="asaas" class="form-text">Gestão de cobranças</div>
        <a target="_blank" href="https://www.asaas.com/sobre-nos">
          <img src="assets/imgs/asaas.png" [height]="50" 
            alt="logo do banco asaas"/>
        </a>
      </div>
    </div>
  </div>
</div>


}@else {
<div class="loader-wrapper">
  <div class="loader loader-1">
    <span class="mdi mdi-loading fs-1 text-primary"></span>
  </div>
</div>
}