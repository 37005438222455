import { Component, OnInit, Input } from '@angular/core';

interface client {
  image: string;
};

@Component({
  selector: 'app-clients-logo',
  templateUrl: './clients-logo.component.html',
  styleUrls: ['./clients-logo.component.css']
})
export class ClientsLogoComponent implements OnInit {

  @Input() brand_class: string | undefined;
  /**
   * Clients Logo
   */
   clients_logo: client[] = [
    {
      image: "assets/imgs/logos-banco/bb.jpg"
    },
    {
      image: "assets/imgs/logos-banco/nubank.svg"
    },
    {
      image: "assets/imgs/logos-banco/qi.svg"
    },
    {
      image: "assets/imgs/logos-banco/santander.svg"
    },
    {
      image: "assets/imgs/logos-banco/shopify.svg"
    },
    {
      image: "assets/imgs/logos-banco/spotify.svg"
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
