import { ChangeDetectorRef, Component } from '@angular/core';

@Component({
  selector: 'app-master-page',
  templateUrl: './master-page.component.html',
  styleUrl: './master-page.component.css'
})
export class MasterPageComponent{
  /**
  * Variáveis para utilização no NavBar e Footer
  */
  Menuoption!: string;
  Settingicon!: boolean;
  linkRedirecionamento!: string;
  hideFooter!: boolean;
  footerVariant!: string;
  isFooterNotFixed: boolean;

  //variáveis de ambiente
  isDesktop: boolean;

  constructor(
    private cdr: ChangeDetectorRef
  ){
    this.isDesktop = window.innerWidth >= 1024
  }

  /**
   * Router activation
   */
  onActivate(componentReference: any) {
    this.footerVariant = componentReference?.footerVariant;
    this.Settingicon = componentReference?.Settingicon;
    this.Menuoption = componentReference?.Menuoption;
    this.hideFooter = componentReference?.hideFooter;
    this.linkRedirecionamento = componentReference?.linkRedirecionamento;
 
    this.cdr.detectChanges();
  }
}
