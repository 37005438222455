import { AfterViewInit, Component, Input, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { TratamentoErrosHttpErrorResponseService } from 'src/app/shared/services/tratamento-erros-http-error-response.service';
import Swal from 'sweetalert2';
import { LoginComponent } from '../login.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormReativoService } from 'src/app/shared/services/form-reativo.service';
import { FeatherModule } from 'angular-feather';
import { ResetChavedeAcessoKeyV1 } from '../../models/reset-chave-de-acesso.model';
import { SAAS } from 'src/app/_core/api';

@Component({
  selector: 'app-form-redefinir-senha',
  templateUrl: './form-redefinir-senha.component.html',
  styles: ``,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    FeatherModule
  ]
})
export class FormRedefinirSenhaComponent implements OnInit, AfterViewInit {
  formResetPassword: FormGroup;

  //variáveis de entrda
  @Input() emailLogin?: string;

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    public formReativo: FormReativoService,
    private renderer: Renderer2
  ) {
    this.formResetPassword = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.email
        ]
      ],
    });
  }
  ngOnInit(): void {
    if (this.emailLogin!) {
      this.controls['email'].setValue(this.emailLogin);
    }
  }

  ngAfterViewInit(): void {
    this.renderer.selectRootElement('#email', true).focus();
  }

  get controls() {
    return this.formResetPassword.controls;
  }

  async resetarSenha(email: string) {
    this.loginService
      .resetpassword(new ResetChavedeAcessoKeyV1(
        email,
        `${SAAS}`
      ))
      .subscribe({
        next: (resultado) => {
          Swal.fire(
            resultado.titulo,
            resultado.message,
            resultado.success ? 'success' : 'error'
          );
          this.voltarAoLogin();
        },
        error: (err) => {
          TratamentoErrosHttpErrorResponseService.tratarErro(err);
        }
      })
  }

  voltarAoLogin() {
    LoginComponent.isRedefinirSenhaSub.next(false);
  }
}
