<div class="modal-content">
  <div class="modal-header d-flex justify-content-between">
    <h5>Informar Cupom</h5>
    <button class="btn" (click)="modalAtivo.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="row">
        <div class="col">
          <label class="form-label">Código do cupom:</label>
          <input type="text" id="codigo-cupom" name="codigo-cupom" class="form-control form-control-sm"
           [(ngModel)]="codigoCupom" (keypress)="keyPressedEnter($event)">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="btn-group">
      <button type="button" class="btn btn-sm btn-warning" (click)="modalAtivo.close()">
        Cancelar
      </button>
      <button type="submit" class="btn btn-sm btn-primary" (click)="aplicarCupom()" [disabled]="codigoCupom.length < 3">
        Confirmar
      </button>
    </div>
  </div>
</div>