import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgbAlert, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalInserirCupomComponent } from 'src/app/pages/components/precos/modal-inserir-cupom/modal-inserir-cupom.component';
import { PrecosComponent } from 'src/app/pages/components/precos/precos.component';
import { CouponsV1Model } from 'src/app/pages/models/coupons-v1.model';
import { PlanoSelecionadoV1Model } from 'src/app/pages/models/plano-selecionado-v1.model';

@Component({
  selector: 'app-detalhes-assinatura',
  standalone: true,
  imports: [
    CommonModule,
    NgbAlert
  ],
  templateUrl: './detalhes-assinatura.component.html',
  styles: ``
})
export class DetalhesAssinaturaComponent {
  //variáveis de ambiente
  planoSelecionado?: PlanoSelecionadoV1Model;
  isDescontoAplicado: boolean;

  //variáveis de entrada
  @Input() isOffCanvas: boolean = false;

  constructor(
    private modalService: NgbModal
  ) {
    if (PrecosComponent.hasPlanoSelecionado()) {
      this.planoSelecionado = PrecosComponent.getPlanoSelecionadoSessionStorage();
      this.isDescontoAplicado = this.planoSelecionado.isCupomAplicado ? this.planoSelecionado.isCupomAplicado : false;
    }
  }
  definirDescontoRecebido() {
    const valorDesconto = this.planoSelecionado.cupomAplicado.valor * parseInt(this.planoSelecionado.planoSelecionado);

    return this.formatandoNumberParaStringEmReal(valorDesconto)
  }

  abrirModalInserirCupom() {
    const modalRef = this.modalService.open(
      ModalInserirCupomComponent,
      {
        size: 'sm',
        centered: true
      }
    );

    modalRef.componentInstance.isOffCanvas = this.isOffCanvas;

    //Escutando a variável resultadoCupom do componente ModalInserirCupom
    modalRef.componentInstance.resultadoCupom.subscribe(resultado => {
      const cupomAplicado: CouponsV1Model = resultado.data;
      const valorDescontoPorMes: number = cupomAplicado.valor;
      this.isDescontoAplicado = true;
      this.planoSelecionado.isCupomAplicado = true;
      this.planoSelecionado.cupomAplicado = cupomAplicado;
      switch (this.planoSelecionado.planoObject.titulo.trim()) {
        case 'Mensal':
          this.planoSelecionado.valorFinal = parseFloat((this.planoSelecionado.valorFinal - valorDescontoPorMes).toFixed(2));
          break;
        case 'Trimestral':
          this.planoSelecionado.valorFinal = parseFloat((this.planoSelecionado.valorFinal - (valorDescontoPorMes * 3)).toFixed(2));
          break;
        case 'Semestral':
          this.planoSelecionado.valorFinal = parseFloat((this.planoSelecionado.valorFinal - (valorDescontoPorMes * 6)).toFixed(2));
          break;
        default:
          break;
      }

      PrecosComponent.planoSelecionadoAutenticadoSubject.next(this.planoSelecionado);
    })
  }

  removerCupom() {
    this.isDescontoAplicado = false;
    this.planoSelecionado.isCupomAplicado = false;
    this.planoSelecionado.cupomAplicado = null;
    this.planoSelecionado.valorFinal = this.planoSelecionado?.planoObject?.valor;

    PrecosComponent.planoSelecionadoAutenticadoSubject.next(this.planoSelecionado);
  }

  formatandoNumberParaStringEmReal(valor: number) {
    const valorSplitted = valor.toFixed(2).toString().split(".");
    const valorEmReal = `${valorSplitted[0]},${valorSplitted[1]}`
    return valorEmReal;
  }

  formantandoStringParaNumber(valor: string) {
    return parseFloat(valor.replace('.', '').replace(',', '.'));
  }

  voltarASelecaoDePlano(){
    PrecosComponent.planoSelecionadoAutenticadoSubject.next(undefined);
    sessionStorage.removeItem('planoSelecionado')
  }
}