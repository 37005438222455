import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CupomsV2Service } from 'src/app/pages/services/cupom-v2.service';
import { ResultV1Model } from 'src/app/shared/models/result-v1.model';
import { TratamentoErrosHttpErrorResponseService } from 'src/app/shared/services/tratamento-erros-http-error-response.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-inserir-cupom',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule
  ],
  templateUrl: './modal-inserir-cupom.component.html',
  styles: ``
})
export class ModalInserirCupomComponent implements OnInit{
  //injeção de depedência
  modalAtivo = inject(NgbActiveModal);
  cuponsV2Service = inject(CupomsV2Service)

  //subjects (comunicacao)
  resultadoCupom = new Subject<ResultV1Model>();

  //variáveis a serem preenchidas ao chamar o modal pelo componentInstance
  isOffCanvas: boolean = false;

  //variáveis de ambiente
  codigoCupom = '';

  ngOnInit(): void {
    const codigoCupomInputEl = document.getElementById('codigo-cupom');
    if(!this.isOffCanvas){
      codigoCupomInputEl.setAttribute('ngbAutoFocus', '');
    }else{
      codigoCupomInputEl.removeAttribute('ngbAutoFocus');
    }
  }

  aplicarCupom() {
    this.processaCupom(this.codigoCupom);
  }

  keyPressedEnter(ev: KeyboardEvent){
    if(ev.key == 'Enter'){
      this.aplicarCupom();
    }
  }

  async processaCupom(codigo: string) {
    await this.cuponsV2Service.getCuponCodigo(codigo).subscribe({
      next: (resultado: ResultV1Model) => {
        if(resultado.success){
          this.modalAtivo.close();
          this.resultadoCupom.next(resultado);
        }else{
          Swal.fire(
            resultado.titulo,
            resultado.message,
            'error'
          );
        }
      },
      error: err => {
        TratamentoErrosHttpErrorResponseService.tratarErro(err);
      }
    })
  }
}
