import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { DadosAccountV1Model } from 'src/app/auth/models/account-v1.model';
import { FormReativoService } from 'src/app/shared/services/form-reativo.service';
import { MasksUtil } from 'src/app/shared/services/masks.util';

@Component({
  selector: 'app-form-emissao-nf',
  templateUrl: './form-emissao-nf.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  styles: ``
})
export class FormEmissaoNfComponent implements OnChanges {
  formEmissaoNF: FormGroup;
  @Input() emailUsuario: string;
  @Input() dadosEmissaoNF: DadosAccountV1Model;
  isUtilizarMesmoEmail: boolean = false;

  static dadosEmissaoNF = new Subject<DadosEmissaoNF>();

  constructor(
    private fb: FormBuilder,
    public formReativo: FormReativoService,
    public masksUtils: MasksUtil,
    private renderer: Renderer2
  ) {
    this.formEmissaoNF = this.fb.group(this.montarFormEmissaoNF());
  }
  ngOnChanges(): void {
    if(this.dadosEmissaoNF!){
      this.preencherForm();
    }
  }

  montarFormEmissaoNF() {
    return {
      razaoSocialNF: [
        '',
        Validators.required
      ],
      cnpjEmpresaNF: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(18)
        ])
      ],
      emailEmpresaNF: [
        '',
        Validators.compose([
          Validators.required,
          Validators.email
        ])
      ]
    }
  }

  get controls() {
    return this.formEmissaoNF.controls;
  }

  preencherForm(){
    this.formEmissaoNF.setValue({
      razaoSocialNF: this.dadosEmissaoNF.nomeEscritorio,
      cnpjEmpresaNF: this.dadosEmissaoNF.cnpjEscritorio,
      emailEmpresaNF: this.dadosEmissaoNF.emailEscritorio
    });

    const dadosAEmitir = {
      razaoSocial: this.controls['razaoSocialNF'].value,
      cnpjEmpresa: this.controls['cnpjEmpresaNF'].value,
      emailEmpresa: this.controls['emailEmpresaNF'].value
    } as DadosEmissaoNF;
    
    FormEmissaoNfComponent.dadosEmissaoNF.next(dadosAEmitir);
  }

  toUpperCase(event: any) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.toUpperCase();
  }
  
  emitirDadosEmissaoNF(){
    if(this.formEmissaoNF.valid){
      const dadosAEmitir = {
        razaoSocial: this.controls['razaoSocialNF'].value,
        cnpjEmpresa: this.controls['cnpjEmpresaNF'].value,
        emailEmpresa: this.controls['emailEmpresaNF'].value
      } as DadosEmissaoNF;
      FormEmissaoNfComponent.dadosEmissaoNF.next(dadosAEmitir);
    }
  }

  utilizarMesmoEmail() {
    if (this.isUtilizarMesmoEmail) {
      this.controls['emailEmpresaNF'].setValue(
       this.emailUsuario
      );
      this.emitirDadosEmissaoNF();
    } else {
      this.controls['emailEmpresaNF'].reset();
    }
  }

  proximoInput(formControl: string, maxLength: number, proxInput: string) {
    if (this.controls[formControl]?.value.length == maxLength) {
      this.renderer.selectRootElement(`#${proxInput}`)?.focus();
    }
  };
}

export interface DadosEmissaoNF {
  razaoSocial: string;
  cnpjEmpresa: string;
  emailEmpresa: string;
}
