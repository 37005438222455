import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ViaCepService {

  constructor(
    private httpClient: HttpClient
  ) { }

  buscarEnderecoPorCep(cep: string){
    return this.httpClient.get<RetornoEndereco>(
      `https://viacep.com.br/ws/${cep}/json/`
    )
  }
}

export class RetornoEndereco {
  constructor(
    public cep: string,
    public logradouro: string,
    public complemento: string,
    public bairro: string,
    public localidade: string,
    public uf: string,
    public erro?: boolean
  ) {}
}
