<section class="home-slider container">
  <ngb-carousel
    [showNavigationArrows]="false"
    [showNavigationIndicators]="false"
    interval="3000"
    [animation]="true"
    [pauseOnFocus]="false"
    [pauseOnHover]="false"
  >
    @for (imagem of carroselImagens; track $index) {
    <ng-template ngbSlide>
      <div class="d-flex align-items-end" style="height: 65vh !important;" [style]="imagem.style"></div>
    </ng-template>
    }
  </ngb-carousel>
  <h4 class="title mb-2 text-center">Etapas simples e intuitivas</h4>
  <p class="text-center pb-5 text-muted para-desc mb-0 mx-auto"><b>Aprimore seu tempo!</b> Com a <strong>extratoOFX</strong> você ganha na produtividade permitindo dedicar seu tempo para outras demandas.
  </p>
</section>
