import { Component } from '@angular/core';

@Component({
  selector: 'app-page-aviso-dispositivos-mobile-e-tablet',
  templateUrl: './page-aviso-dispositivos-mobile-e-tablet.component.html',
  styleUrl: './page-aviso-dispositivos-mobile-e-tablet.component.css'
})
export class PageAvisoDispositivosMobileETabletComponent {

}
