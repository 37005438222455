import { Component, OnInit, Input } from '@angular/core';
import { IconeBarraTarefasModel } from './models/icone-barra-tarefas-model';

@Component({
  selector: 'app-pagetitle',
  templateUrl: './pagetitle.component.html',
  styles: `
    .page-title-box, .breadcrumb{
      background: transparent !important;
    }
  `
})

/**
 * Page-Title Component
 */
export class PagetitleComponent implements OnInit {
  /**
   * Variáveis de entrada
   */
  @Input()
  breadcrumbItems!: Array<{
    active?: boolean;
    label?: string;
    linkRedirecionamento?: string;
  }>;
  
  @Input() title: string | undefined;
  @Input() toolbar?: Array<IconeBarraTarefasModel>;

  @Input() funcaoToolbar?: Function;

  constructor() { }

  ngOnInit(): void {
  }
}
