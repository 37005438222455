<app-loader></app-loader>
<div class="modal-content rounded shadow border-0">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalCenterTitle">{{isRedefinirSenha ? 'Redefinir Senha' : 'Login'}}</h5>
    <button type="button" class="btn btn-close btn-lg" data-dismiss="modal" aria-label="Close"
      (click)="fecharModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="feature-form">
      <div class="text-center">
        <img src="assets/imgs/extratoofx-color.png" height="80rem" alt="">
      </div>

      <div class="content mt-4 pt-2">
        @if(!isRedefinirSenha){
        <form class="theme-form" [formGroup]="loginForm">
          <div class="row">
            <div class="col-12">
              <div class="mb-3">
                <label class="form-label">E-mail: <span class="text-danger">*</span></label>
                <div class="form-icon position-relative">
                  <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                  <input class="form-control ps-5" type="email" placeholder="ex.: talita@contabil.com"
                    formControlName="email" id="email-acesso" ngbAutofocus>
                  @if (loginForm.controls['email'].touched
                  && loginForm.controls['email'].errors?.['required'] && !isRedefinirSenha) {
                  <div class="text text-danger mt-1">*E-mail obrigatório.</div>
                  }
                  @if (loginForm.controls['email'].touched
                  && loginForm.controls['email'].errors?.['email']) {
                  <div class="text text-danger mt-1">*Informe um email válido.</div>
                  }
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-3">
                <label class="form-label">Senha: <span class="text-danger">*</span></label>
                <div class="form-icon position-relative">
                  <i-feather name="key" class="fea icon-sm icons"></i-feather>
                  <input class="form-control ps-5" [type]="mostrar ? 'text' : 'password'" placeholder="*********"
                    formControlName="password">
                  @if (loginForm.controls['password'].touched && loginForm.controls['password'].errors?.['required']) {
                  <div class="text text-danger mt-1">
                    Senha obrigatória
                  </div>
                  }
                </div>
              </div>
              <div [ngClass]="{'d-flex justify-content-between' : !isMobile}">
                <div class="form-check">
                  <input type="checkbox" id="view-password" class="form-check-input cursor-pointer"
                    [ngModelOptions]="{standalone: true}" [(ngModel)]="mostrar">
                  <label for="view-password" class="form-check-label cursor-pointer">
                    {{mostrar ? 'Esconder senha' : 'Mostrar senha'}}
                  </label>
                </div>
                <div class="text-end text-md-start">
                  <span class="link cursor-pointer text-primary" (click)="redefinirSenha()">
                    Esqueceu sua chave de acesso?
                  </span>
                </div>
              </div>
            </div>

            <div class="col-lg-12 mt-2 mb-0">
              <div class="d-grid">
                <button class="btn btn-primary" type="button" [disabled]="!loginForm.valid" (click)="onSubmit()">
                  Entrar
                </button>
              </div>
            </div>
          </div>
        </form>
        }@else {
        <app-form-redefinir-senha
          [emailLogin]="controls['email']?.value">
        </app-form-redefinir-senha>
        }
      </div>
    </div>
  </div>
</div>
