<form [formGroup]="formResetPassword">
  <div class="row g-3">
    <div class="col-lg-12">
      <label class="col-form-label">Digite seu email:</label>
      <input class="form-control" type="email" formControlName="email" placeholder="exemplo@email.com" id="email"
        [ngClass]="formReativo.definindoClasseValidator(formResetPassword, 'email')">
    </div>
    <div class="text-end mt-3">
      <span class="link cursor-pointer text-primary" (click)="voltarAoLogin()">
        Lembrei a chave de acesso
      </span>
    </div>
    <div class="col-lg-12">
      <div class="d-grid">
        <button type="button" class="btn btn-primary" [disabled]="controls['email'].invalid"
          (click)="resetarSenha(controls['email'].value)">
          Enviar código
        </button>
      </div>
    </div>
  </div>
</form>