<div class="row justify-content-center">
  @for(data of pricingData;track $index){
  <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2 text-sm-start ng-star-inserted">
    <div class="card pricing pricing-primary business-rate shadow border-0 rounded"
      [ngClass]="{'bg-light': !data.melhorOpcao}">
      <div class="ribbon ribbon-right ribbon-warning overflow-hidden"><span
          class="text-center d-block shadow small h6">{{data.melhorOpcao}}</span></div>
      <div class="card-body">
        <h6 class="title fw-bold text-uppercase text-primary mb-4">{{data.titulo}}</h6>
        <div class="d-flex mb-4">
          <span class="h4 mb-0 mt-2">R$</span>
          <span class="price h1 mb-0">{{data.valor}}</span>
          <span class="h4 align-self-end mb-1">,{{data.ciclo}}</span>
        </div>
        <ul class="list-unstyled mb-0 ps-0">
          @if(data.isDesc){
          <li class="h6 text-danger mb-0"><span class="text-primary h5 me-2"><i
                class="uil uil-times-circle align-middle text-danger"></i></span>{{data.desconto}}</li>
          }@else{
            <li class="h6 mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>{{data.desconto}}</li>
          }
        </ul>
        @for(item of data.list;track $index){
        <ul class="list-unstyled mb-0 ps-0">
          <li class="h6 text-muted mb-0"><span class="text-primary h5 me-2"><i
                class="uil uil-check-circle align-middle"></i></span>{{item}}</li>
        </ul>
        }
        <a href="javascript:void(0)" class="btn btn-primary mt-4" (click)="abrirModalRegistroNovoAssinante('')">
          {{data.btn}}
        </a>
      </div>
    </div>
  </div>
  }
  <!--end col-->
</div>
