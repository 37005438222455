import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoginModel } from "../models/login.model";
import { API_CONVERSOR } from "src/app/_core/api";
import { SecurityUtil } from "src/app/_core/utils/security.util";
import { ResultV1Model } from "src/app/shared/models/result-v1.model";
import { NewAccountV1Interface, UpdateAccountV1Interface } from "../models/account-crud-v1-interface";
import { ResetChavedeAcessoKeyV1 } from "../models/reset-chave-de-acesso.model";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private readonly http: HttpClient,
    private readonly securityUtil: SecurityUtil
  ) { }

  autenticar(
    login: LoginModel
  ) {
    return this.http.post<ResultV1Model>(
      `${API_CONVERSOR}/v1/auth`,
      login
    );
  };

  criarNovaContaAvaliacao(newAcc: NewAccountV1Interface) {
    return this.http.post<ResultV1Model>(
      `${API_CONVERSOR}/v1/account/newaccount/avaliacao`,
      newAcc,
    );
  };

  estenderPeriodoAvaliacao(idAccount: string) {
    return this.http.post<ResultV1Model>(
      `${API_CONVERSOR}/v1/auth/retest`,
      idAccount
    );
  };

  /**
   * serviço de refresh token
   */
  refreshtoken() {
    return this.http.post<ResultV1Model>(
      `${API_CONVERSOR}/v1/auth/refreshtoken`,
      null,
      { headers: this.securityUtil.composeHeader() }
    );
  };

  /**
   * serviço de reset password
   */
  resetpassword(
    model: ResetChavedeAcessoKeyV1
  ) {
    return this.http.post<ResultV1Model>(
      `${API_CONVERSOR}/v1/auth/resetaccesskey`,
      { model }
    );
  };

  /**Get by id */
  getContaById() {
    return this.http.get<ResultV1Model>(
      `${API_CONVERSOR}/v1/account/id`,
      { headers: this.securityUtil.composeHeader() }
    )
  }
  
  /**Get by id */
  getContaByIdUserNaoAutenticado(id: string) {
    return this.http.get<ResultV1Model>(
      `${API_CONVERSOR}/v1/account/contrato/codigo/${id}`,
      { headers: this.securityUtil.composeHeader() }
    )
  }

  /**Get dados da avaliação by idAccount */
  getDadosAvaliacaoById(_idAccount: string) {
    const params = new HttpParams({
      fromObject: {
        user: _idAccount
      }
    })
    return this.http.get<ResultV1Model>(
      `${API_CONVERSOR}/v1/account/avaliacao`,
      {
        headers: this.securityUtil.composeHeader(),
        params
      }
    )
  }

  /**Put de update da conta do usuário */
  updateAccount(conta: UpdateAccountV1Interface){
    return this.http.put<ResultV1Model>(
      `${API_CONVERSOR}/v1/account/update`,
      conta,
      {headers: this.securityUtil.composeHeader()}
    )
  }

  /**Put para update da senha de acesso do usuário */
  updateAccessKey(senha: string){
    const newaccesskey = senha;
    return this.http.put<ResultV1Model>(
      `${API_CONVERSOR}/v1/auth/updateaccesskey`,
      {newaccesskey},
      {headers: this.securityUtil.composeHeader()}
    )
  }
}
