import { CommonModule } from '@angular/common';
import { Component, Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FeatherModule } from 'angular-feather';
import { LoaderService } from 'src/app/_core/services/loader.service';
import { SecurityUtil } from 'src/app/_core/utils/security.util';
import { LoginModel } from 'src/app/auth/models/login.model';
import { ResultV1Model } from 'src/app/shared/models/result-v1.model';
import Swal from 'sweetalert2';
import { TratamentoErrosHttpErrorResponseService } from 'src/app/shared/services/tratamento-erros-http-error-response.service';
import { LoaderComponent } from "../../_core/loader/loader.component";
import { RegistrationComponent } from '../registration/registration.component';
import { LoginService } from '../services/login.service';
import { BehaviorSubject } from 'rxjs';
import { FormRedefinirSenhaComponent } from './form-redefinir-senha/form-redefinir-senha.component';
import { AccountV1Model } from '../models/account-v1.model';
import { LoginAccessV1Model } from '../models/login-access-v1.model';

@Injectable({
  providedIn: 'root',
})

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  standalone: true,
  imports: [
    FeatherModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoaderComponent,
    NgbTooltipModule,
    FormRedefinirSenhaComponent
  ]
})
export class LoginComponent {
  //variáveis de ambiente
  loginForm: FormGroup;
  mostrar: boolean = false;
  static isRedefinirSenhaSub = new BehaviorSubject<boolean>(false);
  isRedefinirSenha: boolean = false;

  //variáveis identificacao de dispositivo
  isMobile: boolean = false;
  isTablet: boolean = false;
  isDesktop: boolean = false;


  constructor(
    public modalAtivo: NgbActiveModal,
    private readonly loginService: LoginService,
    private readonly loaderService: LoaderService,
    private modalService: NgbModal,
    public route: Router,
    private fb: FormBuilder
  ) {
    //Setando o tipo de dispositivo
    this.isMobile = window.innerWidth < 768;
    this.isTablet = (window.innerWidth >= 768 && window.innerWidth < 992);
    this.isDesktop = window.innerWidth >= 992;

    LoginComponent.isRedefinirSenhaSub.subscribe(
      op => this.isRedefinirSenha = op
    )

    this.loginForm = this.fb.group({
      email: ['', [
        Validators.required,
        Validators.email
      ]],
      password: ['',
        Validators.required
      ],
    });
  }

  get controls() {
    return this.loginForm.controls;
  }

  async onSubmit() {
    const dadosLogin = new LoginModel(
      this.loginForm.value['email'],
      this.loginForm.value['password']
    )
    this.fazerLogin(dadosLogin);
  }

  fecharModal() {
    LoginComponent.isRedefinirSenhaSub.next(false);
    this.modalAtivo.close();
  }

  /**
   * Função utilizada para fazer login na plataforma
   * @param dadosLogin Os dados para acesso, sendo eles email e senha
   * @param isFinalizarCadastro Opcional, caso seja true, ao invés de ser direcionado para o painelConversor,
   * o usuário será direcionado para o componente Contratar
   */
  async fazerLogin(dadosLogin: LoginModel, isFinalizarCadastro?: boolean, isAutoRegistro?: boolean) {
    await this.loginService.autenticar(dadosLogin).subscribe({
      next: (resultado: ResultV1Model) => {
        if (resultado.success) {
          this.loaderService.startLoader();
          const account: LoginAccessV1Model = resultado.data.account;

          /**
           * Inicilizando os parâmetros de avaliação do tipo de conta, que podem não ser retornados pelo processo de login
           * impedindo possíveis erros de 'can not read properties of undefined'
           */
          !account.isAssinatura ? account.isAssinatura = false : null;
          !account.isManager ? account.isManager = false : null;
          !account.isParceiro ? account.isParceiro = false : null;
          !account.isTeste ? account.isTeste = false : null;
          !account.isReteste ? account.isReteste = false : null;

          try {
            /**Setando o token e o account no localStorage */
            SecurityUtil.set(
              account,
              resultado.data.token,
            );

            /**Setando o account do usuário a logar */
            this.getUserAccById();

            LoginComponent.userLogado.subscribe(async user => {
              /**avaliando se contém dados válidos no user que foi emitido */
              if (user!) {
                //caso tenha

                /**Avaliando qual processo chamou o componente de login */
                if (isFinalizarCadastro!) {
                  /**
                   * Se for o processo de finalização de cadastro, 
                   * o usuário é redirecionado para assinantes/contratar
                   * para que possa finalizar a contratação
                   */
                  this.route.navigate(['/assinantes/contratar']);
                } else {
                  /**Se não for, o usuário será redirecionado para o painel do conversor */
                  this.route.navigate([`/assinantes/conversor`]);
                }
              } else {
                /**
                 * Se o valor do user for undefined ou null, indica que houve um erro durante a autenticação
                 * então o usuário será redirecionado para a página home
                 * sendo assim impedido de logar na plataforma
                 */
                this.route.navigate([`/home`]);
              }
            });
          } catch (error) {
            console.log('error logando');
            console.log(error);
            //em caso de erros, será feito a limpeza dos dados salvos no localStorage
            localStorage.clear();
          }

          /**
           * Se o componente que estiver chamando esta função não for o AutoRegistro
           * Indica que foi o modal Registration, então, será necessário fechá-lo
           */
          if(!isAutoRegistro){
            //fechando o modal
            this.modalAtivo.close();
          }
        } else {
          Swal.fire(
            resultado.titulo,
            resultado.message,
            'error'
          );
        };
      },
      error: (err) => {
        console.log('err');
        console.log(err);
        if (err.error.error == 'expiredtest') {
          /**Se o error == 'expiredtest' indica que o período de teste expirou */
          this.alertaFazerResteste(dadosLogin, err.error);
        } else if (err.error.error == 'expiredretest') {
          /**Se o error == 'expiredretest' indica que o período de reteste expirou */
          this.alertaContratar(err.error);
        } else if (err.error.error == 'expiredsignature') {
          /**Se o error == 'expiredsignature' indica que o período da assinatura expirou e não houve a renovação */
          this.alertaRenovarContratacao(err.error);
        } else if (err.error.error == 'notaccesskey') {
          /**Se o error == 'notaccesskey' indica que a senha está incorreta */
          this.alertaResetarSenha(err.error);
        } else if (err.error.error == 'notfound') {
          /**Se o error == 'notaccesskey' indica que a senha está incorreta */
          this.alertaEmailNaoLocalizado(err.error);
        } else {
          TratamentoErrosHttpErrorResponseService.tratarErro(err);
        }
      }
    });
  }

  alertaFazerResteste(dadosLogin: LoginModel, error: ResultV1Model) {
    Swal.fire({
      title: error.titulo,
      text: `
        Teste expirado! Seu acesso teste expirou. Contrate nossos serviços ou estenda o teste por mais 5 dias.
      `,
      showCancelButton: false,
      showConfirmButton: true,
      showDenyButton: true,
      denyButtonText: 'Contratar',
      confirmButtonText: 'Estender Período de Teste'
    }).then(
      async (resultado) => {
        if (resultado.isConfirmed) {
          if (error.data!) {
            await this.loginService.estenderPeriodoAvaliacao(error.data).subscribe({
              next: (resultado) => {
                Swal.fire(
                  resultado.titulo,
                  resultado.message,
                  resultado.success ? 'success' : 'error'
                ).then(() => {
                  this.fazerLogin(dadosLogin);
                });
              },
              error: (err) => {
                TratamentoErrosHttpErrorResponseService.tratarErro(err);
              }
            })
          }
        } else if (resultado.isDenied) {
          if (error.data!) {
            /**
             * Será feito um post na rota de estender o período de avaliação,
             * para que o usuário logue na plataforma e seja direcionado para
             * o componente Contratar
             */
            await this.loginService.estenderPeriodoAvaliacao(error.data).subscribe({
              next: () => {
                this.fazerLogin(dadosLogin, true);
              },
              error: (err) => {
                TratamentoErrosHttpErrorResponseService.tratarErro(err);
              }
            })
          }
        }
      }
    );
  }

  alertaContratar(err?: ResultV1Model) {
    Swal.fire({
      title: err.titulo,
      text: err.message,
      showConfirmButton: true,
      confirmButtonText: 'Contratar'
    }).then(result => {
      if (result.isConfirmed) {
        this.modalAtivo.close();
        this.loginService.getContaByIdUserNaoAutenticado(err.data.codigo).subscribe({
          next: resultado => {
            sessionStorage.setItem('userAccount', btoa(JSON.stringify(resultado.data)));
            this.route.navigate(['/nao-assinantes/contratar']);
          },
          error: err => TratamentoErrosHttpErrorResponseService.tratarErro(err)
        });
      }
    });
  }

  alertaEmailNaoLocalizado(err: ResultV1Model) {
    Swal.fire({
      title: err.titulo,
      text: err.message,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Criar Conta'
    }).then(
      (result) => {
        if (result.isConfirmed) {
          this.modalAtivo.close();
          this.abrirModalCriarContaAvaliacao();
        }
      }
    );
  }

  alertaRenovarContratacao(err: ResultV1Model) {
    Swal.fire({
      title: err.titulo,
      text: err.message,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Renovar contratação'
    }).then(() => { return });
  }

  alertaResetarSenha(err: ResultV1Model) {
    Swal.fire({
      title: err.titulo,
      text: err.message,
    });
  }

  abrirModalCriarContaAvaliacao() {
    let modalRef = this.modalService.open(
      RegistrationComponent,
      {
        modalDialogClass: 'modal-dialog',
        size: 'md',
        scrollable: true,
        centered: true,
        backdrop: 'static',
        keyboard: true,
      }
    )

    modalRef.componentInstance.planoSelecionado = undefined;
  }

  redefinirSenha() {
    LoginComponent.isRedefinirSenhaSub.next(true);
  }

  /**Função utilizada para setar o Account do usuário no sessionStorage e emitir o valor no subject userLogado */
  private async getUserAccById() {
    await this.loginService.getContaById().subscribe({
      next: resultado => {
        sessionStorage.setItem('userAccount', btoa(JSON.stringify(resultado.data)))
        LoginComponent.userLogado.next(resultado.data)
      }
    })
  }

  //inicio subjects
  public static userLogado = new BehaviorSubject<AccountV1Model | undefined>(undefined);

  public static reiniciarUserLogado() {
    LoginComponent.userLogado = new BehaviorSubject<AccountV1Model | undefined>(undefined);
  }
  //fim subjects
}
