import { Component } from '@angular/core';

@Component({
  selector: 'app-conversoes-disponiveis',
  templateUrl: './conversoes-disponiveis.component.html',
  styles: ``
})
export class ConversoesDisponiveisComponent {
  conversoesLista: Array<{
    icone: string;
    titulo: string,
    descricao: string
  }> = [
    {
      // icone: "/assets/icons/pdf-file.png",
      icone: "mdi mdi-file-pdf-box h1 text-danger",
      titulo: "Conversão Pdf para Ofx",
      descricao: "Conversor de extratos bancários em PDF para ofx"
    },
    {
      // icone: "/assets/icons/excel.svg",
      icone: "mdi mdi-file-excel-box h1 text-success",
      titulo: "Conversão Excel para Ofx",
      descricao: "Conversor de extratos bancários em EXCEL para ofx"
    },
    {
      icone: "mdi mdi-file-delimited h1 text-primary",
      // icone: "https://img.icons8.com/glyph-neue/64/csv.png",
      titulo: "Conversão Csv para Ofx",
      descricao: "Conversor de extratos bancários em csv para ofx"
    },
    {
      // icone: "https://img.icons8.com/ios/50/html-filetype--v1.png",
      icone: "mdi mdi-text-box h1 text-warning",
      titulo: "Conversão Html para Ofx",
      descricao: "Conversor de extratos bancários em HTML para ofx"
    },
  ];

  isDesktop: boolean = false;

  constructor() {
    this.isDesktop = window.innerWidth >= 1024 ? true : false;
  }
}
