import { Injectable } from "@angular/core";

@Injectable()

export class MasksUtil {
  //Funções utilizadas como mask em inputs

  maskCpf(event: any): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value
      .replace(/\s/g, '')
      .replace(/[^0-9]/g, '') //Caso tenha algum caracter que seja diferente de 0-9 substitui por ''
      .slice(0, 11) //Caso tenha mais de 11 caracteres, corta do primeiro ao caracter na posição 10 - a posição '11' é ignorada
      .replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        '$1.$2.$3-$4'
      ) //Captura partes do texto e substitui pelo texto formatado com os caracteres especiais;
  };

  maskCnpj(event: any) {
    const input = event.target as HTMLInputElement;
    input.value = input.value
      .replace(/[^0-9]/g, '')
      .slice(0, 14)
      .replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5'
      )
  }

  maskCpfCnpj(event: any) {
    const input = event.target as HTMLInputElement;
    input.value = input.value
    .replace(/[^0-9]/g, '')
    .slice(0, 14);
    if (input.value.length === 11) {
      input.value = input.value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,
        '$1.$2.$3-$4'
      );
    } else if (input.value.length === 14) {
      input.value = input.value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5'
      )
    }
  }

  maskPhone(event: any) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/\s/g, '').replace(/[^0-9]/g, '')
      .slice(0, 11)
      .replace(
        /(\d{2})(\d{5})(\d{4})/,
        '($1) $2-$3'
      )
  }

  maskCep(event: any) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/\s/g, '').replace(/[^0-9]/g, '')
      .slice(0, 8)
      .replace(
        /(\d{5})(\d{3})/,
        '$1-$2'
      )
  }

  maskNumeroCartao(event: any) {
    const input = event.target as HTMLInputElement;
    input.value = input.value
      .replace(/\s/g, '')
      .replace(/[^0-9]/g, '')
      .slice(0, 16)
      .replace(
        /(\d{4})(\d{4})(\d{4})(\d{4})/,
        '$1 $2 $3 $4'
      )
  }

  maskMesAno(event: any) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/\s/g, '').replace(/[^0-9]/g, '')
      .slice(0, 6)
      .replace(
        /(\d{2})(\d{4})/,
        '$1/$2'
      )
  }
}