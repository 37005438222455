import { Component } from '@angular/core';

@Component({
  selector: 'app-como-funciona',
  templateUrl: './como-funciona.component.html'
})
export class ComoFuncionaComponent {

  /**
   * Imagens do Carrosel
   */
  carroselImagens = [
    {
      style: "background: no-repeat top/100% url('assets/imgs/banners/00-escolha-o-que-converter.png');"
    },
    {
      style: "background: no-repeat top/100% url('assets/imgs/banners/01-upload-do-extrato-pdf.png');"
    },
    {
      style: "background: no-repeat top/100% url('assets/imgs/banners/02-selecione-o-banco-e-dados-da-agencia-e-conta.png');"
    },
    {
      style: "background: no-repeat top/100% url('assets/imgs/banners/03-selecione-o-layout-do-extrato.png');"
    },
    {
      style: "background: no-repeat top/100% url('assets/imgs/banners/04-confira-os-dados-obtidos-para-conversao.png');"
    },
    {
      style: "background: no-repeat top/100% url('assets/imgs/banners/05-extrato-convertido-em-ofx.png');"
    },
  ];

}
