<div class="container">
  <div class="row justify-content-center">
    <div class="col-12 text-center">
      <div class="section-title pb-2">
        <h4 class="title" data-aos="fade-up" data-aos-duration="1000">Casos de sucesso</h4>
        <!-- <p class="text-muted para-desc mb-0 mx-auto" data-aos="fade-up" data-aos-duration="1400">Depoimentos de
          profissionais que usam o
          <span class="text-primary fw-bold">extratoOFX</span> no dia a dia de suas demandas de conversor de PDF
          para OFX.
        </p> -->
      </div>
    </div>
  </div>
  <!--end row-->
  <div class="row justify-content-center">
    <div class="col-lg-12">
      <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
    </div>
  </div>
</div>

<!-- <div class="container pt-5">
  <app-clients-logo brand_class="col-lg-2 col-md-2 col-6 text-center item-img"></app-clients-logo>
</div> -->
