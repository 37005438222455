import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API_CONVERSOR } from "src/app/_core/api";
import { SecurityUtil } from "src/app/_core/utils/security.util";

@Injectable({
  providedIn: 'root'
})
export class CupomsV2Service {

  constructor(
    private http: HttpClient,
    private securityUtil: SecurityUtil,
  ) { }

  getCuponCodigo(
    codigo: string
  ) {
    return this.http.get(
      `${API_CONVERSOR}/v1/coupon/codcupom/${codigo}`,
      { headers: this.securityUtil.composeHeaderConversor() }
    );
  }
}
