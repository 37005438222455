import { Component, Input } from '@angular/core';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { FeatherModule } from 'angular-feather';

@Component({
  selector: 'app-botao-back-to-top',
  templateUrl: './botao-back-to-top.component.html',
  styleUrl: './botao-back-to-top.component.css'
})
export class BotaoBackToTopComponent {
  @Input() scrollTo: string = '#home';
}
