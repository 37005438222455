<div class="row g-5 g-md-2 justify-content-md-center">
    @for(conversao of conversoesLista;track $index){
    <div class="col-12 col-md-6 col-lg-3 text-center text-md-start">
        <div class="features feature-primary my-3">
            <div class="image position-relative d-inline-block">
                <!-- <img src="{{conversao.icon}}" [height]="35"><img> -->
                <i class="{{conversao.icone}}"></i>
            </div>
            <div class="content mt-4">
                <h5>{{conversao.titulo}}</h5>
                <p class="text-muted mb-0">{{conversao.descricao}}</p>
            </div>
        </div>
    </div>
    }
    <p class="pt-2 text-muted para-desc mb-0 mt-lg-3 mx-auto text-center" [ngClass]="{'text-nowrap' : isDesktop}">
        Deseja conhecer nossa plataforma? 
        <a href="https://doc.extratoofx.com" target="_blank" class="link text-black text-decoration-underline text-nowrap">
            Clique aqui
        </a> e tenha acesso à nossa documentação.
    </p>
</div>