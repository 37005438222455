import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./pages/pages.module')
    .then(m => m.PagesModule)
  },
  {
    path: 'assinantes',
    loadChildren: () => import('./routes/assinantes/assinantes.module')
      .then(m => m.AssinantesModule)
  },
  {
    path: 'nao-assinantes',
    loadChildren: () => import('./routes/nao-assinantes/nao-assinantes.module')
      .then(m => m.NaoAssinantesModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module')
      .then(m => m.AuthModule)
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
      scrollOffset: [0, 0],
      // Enable scrolling to anchors
      anchorScrolling: "enabled"
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
